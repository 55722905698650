<template>
  <v-app>
    <v-container>
      <div class="d-flex align-center justify-center" style="height: 96vh">
        <div>
          <div class="text-h6 mb-3 text-center">{{ $t('labels.login') }}</div>
          <v-card outlined max-width="400px" class="mx-auto">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="email" autocomplete="off" :label="$t('labels.account')" outlined dense
                    hide-details></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="password" autocomplete="off" outlined dense hide-details @keyup.enter="login"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                    :label="$t('labels.password')" counter @click:append="showPassword = !showPassword"></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12">
                  <v-btn block color="success" @click="login" :disabled="isDisabledBtn">{{ $t('labels.login') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Login",
  data: () => ({
    email: null,
    password: null,
    showPassword: false,
    isLoading: false,
  }),
  computed: {
    isDisabledBtn() {
      return !this.email || !this.password
    }
  },
  mounted() {
    this.checkLogin()
  },
  methods: {
    checkLogin() {
      const spt = localStorage.getItem('_spt')
      if (spt) {
        this.$router.push({ name: 'Notification' })
        /* if (this.checkPermission(['pos_goods_receipt_normal'])) {
          this.$router.push({ name: 'GoodsReceiptNormal' })
        } else {
          this.$router.push({ name: 'Notification' })
        } */
      }
    },
    async login() {
      if (this.isDisabledBtn) {
        return false
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        const { data } = await httpClient.post('/login', {
          email: this.email,
          password: Buffer.from(this.password, 'utf8').toString('base64'),
        })
        if (!data || !data.token) {
          this.$vToastify.error('Có lỗi xảy ra khi đăng nhập. Vui lòng liên hệ CSKH để được hỗ trợ!')
          return false
        }
        localStorage.setItem('_spt', data.token)
        this.$vToastify.success('Đăng nhập thành công')
        this.$router.push({ name: 'Notification' })
        // await this.$router.push({ name: 'GoodsReceiptNormal' })
        /* if (this.checkPermission(['pos_goods_receipt_normal'])) {
          this.$router.push({ name: 'GoodsReceiptNormal' })
        } else {
          this.$router.push({ name: 'Notification' })
        } */
      } catch (e) {
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg);
        this.isLoading = false
      }
    },
  }
}
</script>

<style scoped></style>
